html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* Teal gradient background */
  background: linear-gradient(45deg, #1de9b6, #08aeea);
  font-family: 'Poppins', sans-serif; /* Using the Poppins font as an example */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use viewport height to ensure it covers the full screen height */
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1; /* This will make the App fill the available space */
}

/* Remove .App-logo if not used */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-content {
  flex-grow: 1; /* ensure this container can grow with the content, this class is for your main content container */
}

/* Remove .App-header if not used or adjust as needed */
.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: White;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Additional styles for Shopify products if needed */
.shopify-product {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

/* Ensure no additional padding or margin is affecting the full height */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Additional global styles */
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

/* Footer or additional content */
.App-footer {
  background: transparent; /* or use the same gradient as the body */
}

/* If you are using any fixed positioning or elements with their own background, 
make sure they also have a transparent background or the gradient. */
.fixed-element {
  background: transparent; /* or the gradient */
}
